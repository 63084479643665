import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { ISideNavUrlLink } from '@sybl/common-models';
import { PermissionsEnum } from '@sybl/feature-permissions-models';
import { LeftSidenavItemComponent } from '../left-sidenav-item/left-sidenav-item.component';
import { ISyblAgency, ISyblAgencyUser } from '@sybl/feature-sybl-models';

@Component({
  standalone: true,
  imports: [CommonModule, LeftSidenavItemComponent],
  selector: 'lib-sidenav-left',
  templateUrl: './left-sidenav.component.html',
  styleUrls: ['./left-sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavLeftComponent implements OnChanges {
  @Input() username!: string;
  @Input() user_id!: string;
  @Input() loggedIn!: boolean | null;
  @Input() permissions!: PermissionsEnum;
  @Input() userLinks: Array<ISideNavUrlLink>;
  @Input() bottomLinks: Array<ISideNavUrlLink>;
  @Input() syblUser: ISyblAgencyUser;
  @Input() individualAccount: boolean | any; 

  _userLinks = [];
  _bottomLinks = [];

  _permissions;

  @Output() leftMenuToggle = new EventEmitter<any>();

  ngOnChanges(changes: SimpleChanges): void {
    if (typeof window !== 'undefined') {
      if (changes['bottomLinks']) {
        this._bottomLinks = changes['bottomLinks'].currentValue;
      }

      if (changes['permissions']) {
        const permissions = changes['permissions'].currentValue;
        if (permissions !== undefined) {
          this._permissions = permissions;

          this._userLinks = this.filterUserLinks(this.userLinks, undefined);
        }
      }
      if (changes['syblUser']) {
        const syblUser = changes['syblUser'].currentValue;
        if (syblUser !== undefined) {

          const filterUser = this.filterUserLinks(
            this.userLinks,
            syblUser.permissions,
          )
          this._userLinks = this.filterIndividualAccounts(filterUser, this.individualAccount );
        
      } else {
          this._userLinks = this.filterUserLinks(this.userLinks, undefined);
        }
      }
    }
  }

  filterUserLinks(userLinks, syblPermissions) {

    if (Number(this._permissions) > 5) {
      return userLinks;
    }

    if (syblPermissions === undefined || syblPermissions === 'GUEST') {
      const links = userLinks
        .map((link) => {
          if (link.syblAdmin !== true && link.syblUser !== true) {
            return link;
          } else return null;
        })
        .filter((entry) => entry !== null);
      return links;
    } else if (syblPermissions === 'SYBL') {
      const links = userLinks
        .map((link) => {
          if (link.syblAdmin !== true) {
            return link;
          } else return null;
        })
        .filter((entry) => entry !== null);
      return links;
    } else if (syblPermissions === 'ACCOUNT_ADMIN') {
      const links = userLinks
        .map((link) => {
          if (link.syblUser !== true) {
            return link;
          } else return null;
        })
        .filter((entry) => entry !== null);
      return links;
    } else if (syblPermissions === 'MANAGER' || syblPermissions === 'OWNER') {
      return userLinks;
    } else return [];
  }

  filterIndividualAccounts(links, individualAccount) {

      if(individualAccount){
      return links.filter(link=>link.syblTeam!==true)
    }else{
      return links
    }
  }

  receivedLeftMenuToggle() {
    this.leftMenuToggle.emit();
  }

  get filterBottomLinks() {
    const syblUser = this.syblUser;
    if (syblUser && syblUser.permissions) {
      const permissions = syblUser.permissions;
      if (
        permissions === 'ACCOUNT_ADMIN' ||
        permissions === 'MANAGER' ||
        permissions === 'OWNER'
      ) {
        return this._bottomLinks;
      } else {
        return this._bottomLinks.filter((link) => link.syblAdmin !== true);
      }
    } else {
      return this._bottomLinks.filter((link) => link.syblAdmin !== true);
    }
  }
}

import { CommonModule } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { ISideNavUrlLink } from '@sybl/common-models';
import { RightSidenavItemComponent } from '../right-sidenav-item/right-sidenav-item.component';
import { PermissionsEnum } from '@sybl/feature-permissions-models';


@Component({
    selector: 'lib-sidenav-right',
    standalone: true,
    imports: [CommonModule, RightSidenavItemComponent ],
    templateUrl: './right-sidenav.component.html',
    styleUrls: ['./right-sidenav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightSidenavComponent implements  OnChanges {
    @Input() username!:string;
    @Input() user_id!:string;
    @Input() loggedIn!: boolean | null;
    @Input() permissions: PermissionsEnum;
    @Input() userLinks!: Array<ISideNavUrlLink> | null
    @Output() linkClicked= new EventEmitter()

    _permissions:number = 0
    safeLinks: Array<ISideNavUrlLink> = [];


    constructor(  ){ }   


    ngOnChanges(changes:SimpleChanges){ 
        if(changes['permissions']){
            const permission: number = changes['permissions'].currentValue;
            this.safeLinks = this.userLinks.filter(userLink=>userLink.permission <= permission)
        }
    }
    get _userLinks(){
        return this.userLinks;
    }

    clickedLink(){
        this.linkClicked.emit()
    }
}

import { CommonModule, NgClass } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AppStatusStore } from '@sybl/core-state';
import { AuthStatusStore } from '@sybl/feature-auth-state';
import { PermissionsEnum } from '@sybl/feature-permissions-models';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-toolbar-avatar',
  standalone: true,
  imports: [CommonModule, NgClass],
  templateUrl: './toolbar-avatar.component.html',
  styleUrls: ['./toolbar-avatar.component.scss'],
})
export class ToolbarAvatarComponent implements OnChanges {
  constructor(private _changeDetectorRef: ChangeDetectorRef) {}
  readonly authStore = inject(AuthStatusStore);
  appStatus = inject(AppStatusStore);

  @Input() avatarSrc: string;
  @Input() avatarSize: string;
  @Input() avatarUndefined?: boolean;
  @Input() username?: string;
  @Input() permissions: PermissionsEnum;

  get defaultAvatar() {
    return this.authStore.defaultAvatar();
  }

  get mobileBrowser() {
    return this.appStatus.mobileBrowser();
  }
  get avatar() {
    if (this.avatarSrc != undefined) {
      return this.avatarSrc;
    } else return '';
  }
  get usernameInitial() {
    if (this.username !== undefined)
      return this.username.substring(0, 1).toUpperCase();
    else return '';
  }
  get _avatarSize() {
    if (this.avatarSize !== undefined) {
      return this.avatarSize;
    } else return 'medium';
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._changeDetectorRef.detectChanges();
  }
}

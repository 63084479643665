import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Observable, Subject, BehaviorSubject } from 'rxjs';

import {
  Router,
  Event as NavigationEvent,
  NavigationEnd,
} from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { ToolbarDumbComponent } from '../toolbar-dumb/toolbar-dumb.component';
import { MatDialog } from '@angular/material/dialog';

import { AppStatusStore } from '@sybl/core-state';
import { AuthStatusStore } from '@sybl/feature-auth-state';
import { UserProfileStore } from '@sybl/feature-users-state';


@Component({
  // tslint:disable-next-line: component-selector
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-feature-toolbar-smart',
  standalone: true,
  imports: [CommonModule, ToolbarDumbComponent],
  template: `
    <lib-toolbar-dumb
      [logoVersion]="'medium'"
      [logoHolderClass]="logoHolderClass$ | async"
      [logoImageClass]="logoImageClass$ | async"
      [toolbarClass]="toolbarClass$ | async"
      [menuClass]="menuClass$ | async"
      [avatarSize]="avatarSize$ | async"
      [hideToolbar]="hideToolbar"
      [siteLogo]="siteLogo"
      [avatarSrc]="avatar"
      [permissions]="permissions"
      [avatarUndefined]="avatarUndefined"
      [username]="username"
      [email]="email"
      [showNavbar]="showNavbar"
      [scrollPos]="scrollPos"
      [routePage]="routePage"
      (leftMenuToggle)="leftMenuClicked()"
      (rightMenuToggle)="rightMenuClicked($event)"
      (clickedLogoOut)="clickedLogo()"
      (clickedShopCart)="shopCartClickReceived()"
    ></lib-toolbar-dumb>
  `,
})
/*

*/
export class ToolbarSmartComponent implements OnDestroy, OnInit, OnChanges {
  readonly appStore = inject(AppStatusStore);
  readonly authStore = inject(AuthStatusStore);
  readonly userProfileStore = inject(UserProfileStore);

  @Output() leftMenuToggle = new EventEmitter();
  @Output() rightMenuToggle = new EventEmitter();
  @Output() clickedLogoOut = new EventEmitter();

  @Input() windowWidth: any;
  @Input() scrollPos: any;
  @Input() leftMenuInput!: any;
  @Input() rightMenuInput!: any;
  @Input() avatar: any;

  // Todo right now not looking if mobile or not...
  mobileBrowser = false;
  private _onDestroy = new Subject();
  logoHolderClass$ = new BehaviorSubject<string>('');
  logoImageClass$ = new BehaviorSubject<string>('');
  toolbarClass$ = new BehaviorSubject<string>('');
  menuClass$ = new BehaviorSubject<string>('');
  avatarSize$ = new BehaviorSubject<string>('');
  pageBase$ = new BehaviorSubject<string>('');
  showNavbar$ = new BehaviorSubject<boolean>(false);

  get siteLogo() {
    return this.appStore.largerLogo();
  }
  get hideToolbar() {
    return this.appStore.hideToolbar();
  }
  get avatarUndefined() {
    return this.userProfileStore.avatarUndefined();
  }
  get routePage(){
    return this.appStore.routePage()
  }
  get username(){ if(this.authStore.email()){return  this.authStore.email().split('@')[0]}else return '' }

  get email(){
    if(this.authStore.email()){return  this.authStore.email().split('@')[0]}else return '' }

  get _avatar() {
    return this.userProfileStore.avatar();
  }
  get user_id() {
    return this.authStore.user_id();
  }
  get loggedIn() {
    return this.authStore.loggedIn();
  }
  get permissions() {
    return this.authStore.permissions();
  }
  get leftMenuStatus() {
    return this.leftMenuInput;
  }
  get showNavbar() {
    return this.showNavbar$.value;
  }

  constructor(
    public dialog: MatDialog,
    //   private authFacade: AuthFacade,
    //   private appShellFacade: AppShellFacade,
    private router: Router //private routerFacade: NgrxRouterFacade
  ) {}

  ngOnInit() {
    /*
    this.appShellFacade.windowWidth$.subscribe((value) => {
      if (value > 800) {
        this.useLargerToolbar();
        this.showNavbar$.next(true);
      } else {
        this.useSmallerToolbar();
        this.showNavbar$.next(false);
      }
    });
    */

    this.router.events
      .pipe(
        map((event: NavigationEvent) => {
          if (event instanceof NavigationEnd) {
            const url = event.url;
            const pageBase = url.split('/')[1];

            this.pageBase$.next(pageBase);
          }
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.logoHolderClass$.complete();
    this.logoImageClass$.complete();
    this.toolbarClass$.complete();
    this.menuClass$.complete();
    this.avatarSize$.complete();
    this.pageBase$.complete();
    this.showNavbar$.complete();

    this._onDestroy.next(true);
  }

  ngOnChanges(changes: SimpleChanges) {

    // Set the toolbar and logo sizes dependent upon the size and position of the sce
    if (typeof window !== 'undefined') {
      if (changes['leftMenuInput']) {
        const inputValue = changes['leftMenuInput'].currentValue;

        if (inputValue == true || this.pageBase$.value !== 'home') {
          this.useSmallerToolbar();
        } else if (this.windowWidth > 800 && this.scrollPos == 0) {
          this.useLargerToolbar();
        }
      }

      if (changes['rightMenuInput']) {
        const inputValue = changes['rightMenuInput'].currentValue;

        if (inputValue == true || this.pageBase$.value !== 'home') {
          this.useSmallerToolbar();
        } else if (this.windowWidth > 800 && this.scrollPos == 0) {
          this.useLargerToolbar();
        }
      }

      if (changes['windowWidth']) {
        const windowWidth = changes['windowWidth'].currentValue;

        if (windowWidth < 800 || this.pageBase$.value !== 'home') {
          this.useSmallerToolbar();
        } else {
          this.useLargerToolbar();
        }
      }

      if (changes['scrollPos']) {
        const currentScrollPos = changes['scrollPos'].currentValue;
        const previousScrollPos = changes['scrollPos'].previousValue;

        if (this.windowWidth < 800 || this.pageBase$.value !== 'home') {
          this.useSmallerToolbar();
        } else if (currentScrollPos <= 10 || previousScrollPos === null) {
          this.useLargerToolbar();
        } else if (
          currentScrollPos > 100 &&
          currentScrollPos > previousScrollPos &&
          this.mobileBrowser === true
        ) {
          this.toolbarClass$.next('hidden-toolbar');
        } else if (currentScrollPos > 10 && previousScrollPos != null) {
          this.useSmallerToolbar();
        }

        if (
          previousScrollPos !== null &&
          currentScrollPos > 100 &&
          currentScrollPos > previousScrollPos
        ) {
        this.appStore.hideToolbarMethod(true);
        } else if (
          previousScrollPos == null ||
          currentScrollPos === 0 ||
          currentScrollPos < previousScrollPos
        ) {
          this.appStore.hideToolbarMethod(false);
        }
      }
    }
  }

  leftMenuClicked(): void {
    this.leftMenuToggle.emit();
    return;
  }

  rightMenuClicked(event) {
    this.rightMenuToggle.emit(event);
  }

  clickedLogo(): void {
    this.clickedLogoOut.emit();
    return;
  }
  shopCartClickReceived() {
    return this.router.navigate(['/shopping-cart/home']);
  }

  useLargerToolbar(): void {
    this.logoHolderClass$.next('toolbar-larger-logo');
    this.logoImageClass$.next('logo-image-large');
    this.toolbarClass$.next('larger-toolbar');
    this.menuClass$.next('larger-menu-button');
    this.avatarSize$.next('toolbar-avatar-large');
    return;
  }

  hideNavbar() {
    return this.showNavbar$.next(false);
  }

  useSmallerToolbar(): void {
    this.logoHolderClass$.next('toolbar-smaller-logo');
    this.logoImageClass$.next('logo-image-small');
    this.toolbarClass$.next('smaller-toolbar');
    this.menuClass$.next('smaller-menu-button');
    this.avatarSize$.next('toolbar-avatar-small');
    return;
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Component,
  HostListener,
  Inject,
  afterNextRender,
  OnInit,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
  effect,
  inject,
  AfterViewInit,
  AfterRenderPhase,
  OnDestroy,
  OnChanges,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ToolbarSmartComponent } from '@sybl/feature-toolbar';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { FeatureBottomInfoBoxComponent } from '@sybl/feature-bottom-info-box';
import { AppStatusStore } from '@sybl/core-state';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { LoginDialogComponent } from '@sybl/feature-auth-ui';
import { BehaviorSubject } from 'rxjs';
import { AuthStatusStore } from '@sybl/feature-auth-state';
import {
  RightSidenavSmartComponent,
  SidenavLeftSmartComponent,
} from '@sybl/feature-sidenav';
import { DOCUMENT, NgClass, isPlatformBrowser } from '@angular/common';
import { interval } from 'rxjs';
import {
  SyblAccountManagementHttpService,
  SyblAgencyStore,
} from '@sybl/feature-sybl-state';
import { UserProfileStore } from '@sybl/feature-users-state';
import { SyblSubscriptionStore } from '@sybl/feature-subscriptions-state';

@Component({
  standalone: true,
  imports: [
    ToolbarSmartComponent,
    MatSidenavModule,
    FeatureBottomInfoBoxComponent,
    RouterModule,
    LoginDialogComponent,
    MatDialogModule,
    SidenavLeftSmartComponent,
    RightSidenavSmartComponent,
    NgClass,
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy, OnChanges {
  title = 'SYBL AI';
  readonly authStore: any = inject(AuthStatusStore);
  readonly store: any = inject(AppStatusStore);
  readonly syblHttpService = inject(SyblAccountManagementHttpService);
  readonly syblAgencyStore = inject(SyblAgencyStore);
  readonly userProfileStore = inject(UserProfileStore);
  readonly subscriptionStore = inject(SyblSubscriptionStore);
  readonly sybilApiService = inject(SyblAccountManagementHttpService);

  @ViewChild(MatSidenav) sidenav!: MatSidenav;

  get leftStatus() {
    return this.store.showLeftSidenav();
  }
  get rightStatus() {
    return this.store.showRightSidenav();
  }
  get permissions() {
    return this.authStore.permissions();
  }
  get windowWidth() {
    // set the latest value for _data BehaviorSubject
    return this.store.width();
  }
  get scrollPos() {
    return this.store.scrollPosition();
  }

  get showRightSide() {
    return this.showRightSide$.value;
  }

  get avatar() {
    return this.userProfileStore.avatar();
  }
  // If you manually click outside of the sidenav, need to register an action showing it is closed in state.
  leftSidenavChanged(event: any) {
    if (event === false) {
      this.store.closeLeftSidenav();
    }
  }

  rightSidenavChanged(event: any) {
    if (event === false) {
      this.store.closeRightSidenav();
    }
  }

  SMALL_LOGO!: string;
  LARGE_LOGO!: string;
  _scrollPos!: number;
  toolBarClass!: string;

  get loading() {
    return this.store.loading();
  }
  get uploading() {
    return this.store.uploading();
  }

  //scrollPos$: Observable<number> = this.appShellFacade.getScrollPos$;
  // $ = Observable<boolean> = this.appShellFacade.rightSideStatus$;

  windowWidthBS$ = new BehaviorSubject<number | null>(null);
  showRightSide$ = new BehaviorSubject<any>(false);
  permissionsBS$ = new BehaviorSubject<number | null>(null);
  defaultAvatar: string;
  avatarUrl: string;
  registeredAvatar: string;
  platform: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: any,

    @Inject('appUrls') appUrls: any,
    public dialog: MatDialog,
    private router: Router,
  ) {
    this.store.setEnvironment(appUrls.ENV_NAME, appUrls.ENV_TYPE);
    this.platform = isPlatformBrowser(this.platformId) ? true : false;

    this.SMALL_LOGO = appUrls.SMALL_LOGO;
    this.LARGE_LOGO = appUrls.LARGE_LOGO;
    this.avatarUrl = appUrls.AVATAR_URL;
    this.defaultAvatar = appUrls.DEFAULT_AVATAR;
    this.registeredAvatar = appUrls.REGISTERED_AVATAR;

    afterNextRender(
      () => {
        this.authStore.checkStatus();

        // Every 15 minutes check auth status
        interval(900000).subscribe((x) => {
          this.authStore.checkStatus();
        });
        //this.chart = new MyChart(this.chartRef.nativeElement);
      },
      { phase: AfterRenderPhase.Write },
    );
  }

  ngOnInit(): void {
    const appDetails = {
      SMALL_LOGO: this.SMALL_LOGO,
      LARGE_LOGO: this.LARGE_LOGO,
    };

    if (typeof window !== 'undefined') {
      this.store.getWindowInfo();

      this.store.setAppDetailsOnLoad(appDetails);

      // These come as an array where effectiveDate is the version
      this.sybilApiService.getKillBillPrices('sybl').subscribe((response) => {
       // const priceLength = response.catalog.length - 1;
        const catalogObject = response.catalog[0];
        this.subscriptionStore.receivedPricing(catalogObject);
      });
    }
    return;
  }

  ngOnDestroy() {
    this.permissionsBS$.complete();
    this.windowWidthBS$.complete();
    this.showRightSide$.complete();
  }

  receivedLeftMenuToggle() {
    this.store.toggleLeftSidenav();
    this.sidenav.toggle();
  }

  receivedRightMenuToggle(event:any) {
    const permValue: any = this.permissionsBS$.value;

    if (event <= 1) {
      this.dialog.open(LoginDialogComponent);
    } else {
      this.store.toggleRightSidenav();

      this.showRightSide$.next(!this.showRightSide$.value);
    }
  }

  rightSideClosed() {
    this.showRightSide$.next(false);
    this.store.closeRightSidenav();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    if (typeof window !== 'undefined') {
      if (changes['scrollPos']) {
        const currentScrollPos = changes['scrollPos'].currentValue;
        this._scrollPos = currentScrollPos;
      }
    }
  }

  backdropClicked() {
    this.store.closeLeftSidenav();
    this.store.closeRightSidenav();
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onResize(_event: Event) {
    this.store.getWindowInfo();
  }

  menuClicked() {
    console.warn('NOT IMPLEMENTED MAY DROP');
  }

  receivedClickedLogo() {
    this.router.navigate(['']);
  }
}

<div style="display: flex;flex-direction: column;height: 100%;">
    <div style="flex: 1;">
        @for (userLink of _userLinks; track userLink.displayName; let idx = $index, e = $even) {
            <lib-left-sidenav-item
                [profileLink]="userLink"
                [user_id]="user_id"
                [permissions]="permissions"
                (leftMenuToggle)="receivedLeftMenuToggle()"
                >
            </lib-left-sidenav-item>
        }
    </div>
    <!--
    @for (let userLink of userLinks; track userLink){
        
    } -->
    <!-- Empty Div to deal with what was Done Above for Scrolling-->
    <div style="flex: 1; display: flex;">
        <div style="margin-top: auto;">
            @for (userLink of filterBottomLinks; track userLink.displayName; let idx = $index, e = $even) {
                <lib-left-sidenav-item
                    [profileLink]="userLink"
                    [user_id]="user_id"
                    [permissions]="permissions"
                    (leftMenuToggle)="receivedLeftMenuToggle()"
                    >
                </lib-left-sidenav-item>
            }
        </div>
    </div>
</div>
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable, inject, effect } from '@angular/core';


//import { contentHeaders } from '@sybl/common-models';
import { UsersWebsocketStore } from '@sybl/feature-websocket-state';
import { getState } from '@ngrx/signals';

@Injectable()
export class UsersWebSocketService {
    userWebsocket:any = inject(UsersWebsocketStore);

    constructor(){
        effect(()=>{
            const state = getState(this.userWebsocket);

        })

    }


}
import { Component, Input, inject, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router'
import { LogoutDialogComponent } from '@sybl/feature-auth-ui'
import { AuthStatusStore } from '@sybl/feature-auth-state'
import { AppStatusStore } from '@sybl/core-state';
import { MatTooltipModule } from '@angular/material/tooltip'
import { SvgIconComponent } from '@sybl/feature-custom-elements-ui'

import { ISideNavUrlLink } from '@sybl/common-models';
import { CommonModule } from '@angular/common';
@Component({
    selector: 'lib-right-sidenav-item',
    imports: [CommonModule, MatTooltipModule, SvgIconComponent],
    standalone: true,
    templateUrl: './right-sidenav-item.component.html',
    styleUrls: ['./right-sidenav-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class RightSidenavItemComponent {
    readonly authStore = inject(AuthStatusStore);
    readonly store = inject(AppStatusStore);
  
    @Input() profileLink!: ISideNavUrlLink;
    @Input() user_id!:string;
    iconColor!:'white';

    constructor(
        public dialog: MatDialog, 
        private router: Router,
      ){ }   
 

    clickLink(){
        //If link is logout do popup dialog
        if(this.profileLink.url==='logout'){
            return this.dialog.open(LogoutDialogComponent)
        }else {
            /**
             * All Navigation links initiate pageLoading action and navigate to corresponding page.
             */
            this.store.loading();
            
            const linkUrl = this.profileLink.url;
            const linkParts = linkUrl.split('/');


            if(linkParts[1] === 'user'){
                
                const formatLink = linkUrl;


                return this.router.navigate([formatLink])
            }else{
                
                return this.router.navigate([linkUrl])
            }
            // Append user_id to user links.
            // Parse the routes

        }
    }

    get url(){
        return this.profileLink.url;
    }

    get displayName(){

        return this.profileLink.displayName;
    }

    get tooltipText(){
        return this.profileLink.description;
    }

    get visible(){
        return this.profileLink.visible;
    }

    get iconName(){
        return this.profileLink.iconName;
    }

}

import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { AuthStatusStore } from '@sybl/feature-auth-state'
import { AppStatusStore } from '@sybl/core-state';
import { CommonModule } from '@angular/common';
import { RightSidenavComponent } from '../right-sidenav/right-sidenav.component';


@Component({
    selector: 'lib-sidenav-right-smart',
    standalone: true,
    imports: [CommonModule, RightSidenavComponent],
    template: `
     <lib-sidenav-right
        [username]="username"
        [userLinks]="rightSideNavLinks"
        [user_id]= "user_id"
        [loggedIn]="loggedIn"
        [permissions]="permissions"
        (linkClicked)="linkClickReceived()"
    ></lib-sidenav-right>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightSidenavSmartComponent {
    readonly authStore = inject(AuthStatusStore);
    readonly store = inject(AppStatusStore);
    
    get username(){ if(this.authStore.email()){return  this.authStore.email().split('@')[0]}else return '' }
    get loggedIn(){ return  this.authStore.loggedIn()}  
    get permissions(){ return  this.authStore.permissions()}
    get user_id(){ return  this.authStore.user_id()}
    get rightSideNavLinks(){ return  this.authStore.rightSideLinks()}     
            
    linkClickReceived(){
        this.store.closeRightSidenav()
    }

}

<div class="profile-name-holder"> 
    <div style="margin: auto;">
        {{username}} 
    </div>
</div>

<ng-container *ngFor="let profileLink of safeLinks">
    <lib-right-sidenav-item
        [profileLink]="profileLink"
        [user_id]="user_id"
        (click)="clickedLink()"
    >
    </lib-right-sidenav-item>
</ng-container>
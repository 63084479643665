import { Route } from '@angular/router';

export const appRoutes: Route[] = [
  {
    path: 'home',
    loadComponent: () =>
      import('@sybl/feature-home-ui').then(
        (c) => c.FeatureHomeHomeComponent
      ),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('@sybl/feature-about-ui').then(
        (mod) => mod.ABOUT_ROUTES
      ),
  },
  {
    path: 'admin',
    
    loadChildren: () =>
      import('@sybl/feature-admin-ui').then((mod) => mod.ADMIN_ROUTES),
  },
  {
    path: 'authorization',
    loadChildren: () =>
      import('@sybl/feature-auth-ui').then((mod) => mod.AUTH_ROUTES),
  },
  /*{
    path: 'faq',
    loadChildren: () =>
      import('@sybl/feature-faq-ui').then(
        (mod) => mod.FAQ_ROUTES
      ),
  },*/
  {
    path: 'help',
    loadChildren: () =>
      import('@sybl/feature-help').then(
        (mod) => mod.HELP_ROUTES
      ),
  },
  {
    path: 'subscriptions',
    loadChildren: () =>
      import('@sybl/feature-subscriptions-ui').then(
        (mod) => mod.SUBSCRIPTION_ROUTES
      ),
  },
  {
    path: 'tenant',
    loadChildren: () =>
      import('@sybl/feature-resellers-ui').then(
        (mod) => mod.RESELLER_ROUTES
      )
  },
  {
    path: 'images',
    loadChildren: () =>
      import('@sybl/feature-images-ui').then(
        (mod) => mod.IMAGE_ROUTES
      ),
  },
  {
    path: 'user',
    loadChildren: () =>
      import('@sybl/feature-users-ui').then(
        (mod) => mod.USER_PROFILE_ROUTES
      ),
  },
  {
    path: 'sybl',
    loadChildren: () =>
      import('@sybl/feature-sybl-ui').then(
        (mod) => mod.SYBL_ROUTES
      ),
  },
  /* {

    path: 'checkout',
    loadChildren: () => import('checkout/Routes').then((m) => m.remoteRoutes),
  },
  {
    path: 'product',
    loadChildren: () => import('product/Routes').then((m) => m.remoteRoutes),
  },
  */

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];

<div class="left-profile-item" (click)="clickLink()">
    <div class="list-text"
        [matTooltip]="tooltipText"
        matTooltipShowDelay="300"
        matTooltipClass="rightsidenav-notification-class"
        >

        <div style="margin-top: auto; margin-bottom: auto;">
            <lib-svg-icon [color]="'white'" [iconName]="iconName" [size]="'large'"/>
        </div>
        
        <div class="sidenav-left-text">
            {{ displayName }}
        </div>
    </div>
</div>
 
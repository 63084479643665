/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApplicationConfig, isDevMode, importProvidersFrom } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { provideServiceWorker } from '@angular/service-worker';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideEffects } from '@ngrx/effects';
import { authStatusFeature } from '@sybl/feature-auth-state'
import { appStatusFeature } from '@sybl/core-state';
import { AuthBackendService, PasswordBackendService, PasswordFormService, PasswordValidators, UsernameValidators, AuthCookieService, BrowserInfoService } from '@sybl/feature-auth-data-access';
import { UsersWebsocketStore,} from '@sybl/feature-websocket-state'
import { WebsocketMessageService } from '@sybl/feature-websocket-services';
import { UsersWebSocketService } from '@sybl/feature-users-services';
import { AppUrlsService, ApplyAdvancedSearchParamaters, ConvertFromClassToMatFormService, StripeHttpService } from '@sybl/common-services';
import { SVGButtonImageService } from '@sybl/feature-custom-elements-ui'
import { ImageSaveService, PdfService, PdfDownloadService } from '@sybl/feature-images-services';
import { AdminSyblHttpService,AdminAgencyHttpService, AdminUserHttpService, MailerService } from '@sybl/feature-admin-state';
import { SyblAccountManagementHttpService } from '@sybl/feature-sybl-state';
import { provideNgxStripe } from 'ngx-stripe';

export const appConfig: ApplicationConfig = { 
  providers: [
      { provide: 'appUrls', useValue: environment.appUrls },
    importProvidersFrom(HttpClientModule),
    AppUrlsService,
    AuthBackendService,  
    PasswordBackendService,
    AuthCookieService,
    UsernameValidators,
    PasswordValidators,
    PasswordFormService,
    BrowserInfoService,
    WebsocketMessageService,
    UsersWebSocketService,
    SVGButtonImageService,
    ImageSaveService,
    PdfService,
    PdfDownloadService,
    AdminSyblHttpService,
    AdminUserHttpService,
    MailerService,
    AdminAgencyHttpService,
    SyblAccountManagementHttpService,
    ConvertFromClassToMatFormService,
    ApplyAdvancedSearchParamaters,
    StripeHttpService,
    provideNgxStripe(environment.appUrls.STRIPE_PUBLIC_KEY),

    provideHttpClient(withFetch()),
    provideClientHydration(), provideRouter(appRoutes), provideAnimations(),
    provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
    }),
    provideStore({ router: routerReducer } ),
    UsersWebsocketStore,

    provideState(appStatusFeature),
    provideState(authStatusFeature),
    provideRouter(appRoutes),
    provideRouterStore(),
    provideAnimations(),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !isDevMode(),
      name: 'SYBL',
    }),
    provideEffects()
],
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit, Input, Output,  EventEmitter, ChangeDetectionStrategy, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import  { CommonModule, NgClass, } from '@angular/common'
//import { SOCShoppingCartFacade } from '@state-of-comedy/feature-shopping-cart/state';
import { Observable } from 'rxjs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { ToolbarAvatarComponent } from '../avatar/toolbar-avatar.component';
import { PermissionsEnum } from '@sybl/feature-permissions-models';
import { ToolbarSiteLogoComponent } from '../site-logo/toolbar-logo.component';

@Component({
    selector: 'lib-toolbar-dumb',
    standalone: true,
    imports: [CommonModule, MatToolbarModule, NgClass, MatIconModule, MatButtonModule, ToolbarAvatarComponent, ToolbarSiteLogoComponent],
    templateUrl: './toolbar-dumb.component.html',
    styleUrls: ['./toolbar-dumb.component.scss'],
    //changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarDumbComponent implements OnInit, OnChanges {
   // quantity$: Observable<any> = this.shoppingCartFacade.quantity$
    @Input() leftMenuInput!: string;

    @Input() logoVersion!: string;
    @Input() logoHolderClass!: string;
    @Input() logoImageClass!: string;
    @Input() toolbarClass!: string;
    @Input() scrollPos!: number;
    @Input() menuClass!: string;
    @Input() siteLogo!: string;
    @Input() avatarSrc!: string;
    @Input() avatarSize!: string;
    @Input() permissions!: PermissionsEnum;
    @Input() showNavbar!: boolean;
    @Input() hideToolbar!: boolean;
    @Input() avatarUndefined?: boolean
    @Input() username: string | null;
    @Input() email: string | null;
    @Input() routePage: string;
    
    get _hideToolbar(){ return this.hideToolbar }
    get avatar(){ return this.avatarSrc }
    get _avatarSrc(){ return this.avatarSrc}

    @Output() leftMenuToggle = new EventEmitter();
    @Output() rightMenuToggle = new EventEmitter();
    @Output() clickedLogoOut = new EventEmitter();
    @Output() clickedShopCart = new EventEmitter();

    radius = 1;
    color = 'yellow';
    centered = false;
    disabled = false;
    unbounded = false;
    terminateOnPointerUp = true;
    rippleColor!: 'white' //='grey';
    isWindow = false;
    
    constructor(
      //  private shoppingCartFacade:SOCShoppingCartFacade
    ) { 
        if(typeof window !== 'undefined'){
            this.isWindow = true;
        }
    }

    ngOnInit(): void { return }

    ngOnChanges(changes: SimpleChanges) {

        // Make sure it does not run on server...
        if(typeof window !=="undefined"){
            if(changes['avatarSize']){
                //console.log('avatarSize', changes['avatarSize'].currentValue)
            }

            if(changes['avatarSrc']){
                const avatarSrc = changes["avatarSrc"].currentValue;
            }
        }
        
    }   

    clickedAvatarGuest() { 
        this.rightMenuToggle.emit(this.permissions)
    }


    clickedAvatarSignedIn() { 
        this.rightMenuToggle.emit(this.permissions)
    }

    leftMenuClicked() {
        this.leftMenuToggle.emit()
    }


    clickedLogo() {
        
        return this.clickedLogoOut.emit();
    }

    get leftMenuStatus() { return this.leftMenuInput; }
    
    _logoVersion = 'smaller'

    shopCartClickReceived(){
        this.clickedShopCart.emit()
    }   
}

import {
  Component,
  ViewEncapsulation,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthStatusStore } from '@sybl/feature-auth-state';
import { SidenavLeftComponent } from './left-sidenav.component';
import { SyblAgencyStore } from '@sybl/feature-sybl-state';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-sidenav-left-smart',
  standalone: true,
  imports: [CommonModule, SidenavLeftComponent],
  template: `
    <div style="display:flex; height: 100%;">
      <lib-sidenav-left
        [username]="username"
        [userLinks]="leftSideNavLinks"
        [user_id]="user_id"
        [loggedIn]="loggedIn"
        [bottomLinks]="leftSideBottomNavLinks"
        [individualAccount]="individualAccount"
        [permissions]="permissions"
        (leftMenuToggle)="receivedLeftMenuToggle()"
        [syblUser]="syblUser"
      ></lib-sidenav-left>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SidenavLeftSmartComponent {
  readonly authStore = inject(AuthStatusStore);
  readonly syblAgency = inject(SyblAgencyStore);

  @Output() leftMenuToggle = new EventEmitter<any>();
  get syblUser() {
    return this.syblAgency.user();
  }

  get username() {
    if (this.authStore.email()) {
      return this.authStore.email().split('@')[0];
    } else return '';
  }
  get loggedIn() {
    return this.authStore.loggedIn();
  }
  get permissions() {
    return this.authStore.permissions();
  }

  get user_id() {
    return this.authStore.user_id();
  }

  get leftSideNavLinks() {
    return this.authStore.leftSideLinks();
  }
  get individualAccount() {
    return this.syblAgency.individualAccount();
  }

  get leftSideBottomNavLinks() {
    if (this.syblUser) {
      const permissons = this.syblUser.permissions;
    }

    return this.authStore.leftSideBottomLinks();
  }
  receivedLeftMenuToggle() {
    this.leftMenuToggle.emit();
  }

}

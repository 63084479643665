
@if(!_hideToolbar){
<mat-toolbar
  class="mat-elevation-z2"
  [ngClass]="toolbarClass"
  style="height: 86px;"
  color="secondary"
>
  <div style="display: flex; flex-direction: column; width: 100%">
    <div
      style="
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        height: 5rem;
      "
    >
      <div style="display: flex; margin: auto;" (click)="leftMenuClicked()">
        <button mat-icon-button aria-label="Toolbar Menu Button">
          <div style="cursor: pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 40 40"
            >
              <path
                d="M1.323 25.665h38.365v5.821H1.323zM1.318 12.94h38.365v5.821H1.318zM1.323.265h38.365v5.821H1.323z"
              />
            </svg>
          </div>
        </button>
      </div>
      <!--
         matRipple 
         [matRippleCentered]="centered" 
         [matRippleDisabled]="disabled" 
         [matRippleUnbounded]="unbounded"
         [matRippleRadius]="radius" 
         [matRippleColor]="'rippleColor'" 
         terminateOnPointerUp="true"

  <soc-svg-icon
  style="padding-right: 0.5em; margin:auto"
  (click)="leftMenuClicked()"
  [color]="'white'"
  [size]="'medium'"
  [iconName]="'menu-icon.svg'"
  >
</soc-svg-icon>
<div style="color: white; margin: auto">SYBL</div>
-->
@if (isWindow){
  <lib-toolbar-site-logo [siteLogo]="siteLogo" [logoVersion]="_logoVersion" [logoHolderClass]="logoHolderClass"
  [logoImageClass]="logoImageClass" (click)="clickedLogo()"
  style="cursor: pointer; margin:auto 0"></lib-toolbar-site-logo>

  <div class="primary-font" style="margin: auto 12px; font-size: 18pt;">{{routePage}} </div>
}     <div
        style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          flex-direction: row;
        "
      >
        <!--
        <ng-container *ngIf="showNavbar">
          <toolbar-nav style="width: 100%; margin: auto;"></toolbar-nav>
        </ng-container>
      --></div> 

      <div style="display: flex">
        <div style="display: flex; margin: auto;">
          @if(permissions === 1 || permissions === undefined){
          <div>
            <lib-toolbar-avatar
              style="cursor: pointer"
              [avatarSrc]="avatar"
              [avatarSize]="avatarSize"
              [avatarUndefined]="avatarUndefined"
              [username]="email"
              [permissions]="permissions"
              (click)="clickedAvatarGuest()"
            ></lib-toolbar-avatar>
          </div>
          } @else if(permissions > 1){
          <div>
            <lib-toolbar-avatar
              style="cursor: pointer"
              [avatarSrc]="_avatarSrc"
              [avatarSize]="avatarSize"
              [permissions]="permissions"
              [username]="email"
              [avatarUndefined]="avatarUndefined"
              (click)="clickedAvatarSignedIn()"
            ></lib-toolbar-avatar>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</mat-toolbar>
}

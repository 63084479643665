import { CommonModule } from '@angular/common';
import { Component, Input, SimpleChanges, OnChanges, ChangeDetectionStrategy } from '@angular/core';

//Import Facades to deal with store at this point...

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'lib-toolbar-site-logo',
    standalone: true,
    imports: [CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <div [ngClass]="logoHolderClass">
        <ng-container>
            <img [ngClass]="logoImageClass" [src]="_siteLogo">
        </ng-container>
    </div>
    `,
    styleUrls: ['./toolbar-logo.component.scss'
    ]
})

export class ToolbarSiteLogoComponent implements OnChanges {
    @Input() siteLogo!: string;
    @Input() logoVersion!: string;
    @Input() logoHolderClass!: string;
    @Input() logoImageClass!: string;

    _siteLogo!: string;
    _large!: boolean;
    activeClass!: string;

    constructor(

    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class.

        if (changes['siteLogo']) {
            this._siteLogo = changes['siteLogo'].currentValue;
        }

        
    }
}
<lib-feature-toolbar-smart
  (leftMenuToggle)="receivedLeftMenuToggle()"
  (rightMenuToggle)="receivedRightMenuToggle($event)"
  (clickedLogoOut)="receivedClickedLogo()"
  [leftMenuInput]="leftStatus"
  [rightMenuInput]="rightStatus"
  [windowWidth]="windowWidth"
  [scrollPos]="scrollPos"
  [avatar]="avatar"
></lib-feature-toolbar-smart>

<mat-sidenav-container>
  <mat-sidenav
    class="sidenav-container"
    (openedChange)="leftSidenavChanged($event)"
    [opened]="leftStatus"
  >
    <lib-sidenav-left-smart (leftMenuToggle)="receivedLeftMenuToggle()">
    </lib-sidenav-left-smart>
  </mat-sidenav>

  <mat-sidenav
    position="end"
    (openedChange)="rightSidenavChanged($event)"
    [opened]="rightStatus"
  >
    <lib-sidenav-right-smart class="right-slide-menu" [ngClass]="toolBarClass">
    </lib-sidenav-right-smart>
    <!--
       

    -->
  </mat-sidenav>

  <mat-sidenav-content class="sidenav-content" style="overflow: visible">
    <router-outlet></router-outlet>
    <lib-bottom-info-box></lib-bottom-info-box>
  </mat-sidenav-content>
</mat-sidenav-container>

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable, Inject, inject } from '@angular/core';
import { Router } from '@angular/router'
import { Location } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';

import {
    map,
    tap,
    catchError
} from 'rxjs/operators'
import {
    Observable,
    of,
    BehaviorSubject,
    Subject,
    empty,
    throwError as observableThrowError,
} from 'rxjs'

//import { contentHeaders } from '@sybl/common-models';
import { UUID } from '@sybl/common-models'
import { IAuth, IAuthResponse, ISessionResponse, ISessionStatus } from '@sybl/feature-auth-models';
import { UsersWebsocketStore } from '@sybl/feature-websocket-state';
import { IWebSocketMessage } from '@sybl/feature-websocket-models';

@Injectable()
export class WebsocketMessageService {
    userWebsocket:any = inject(UsersWebsocketStore);

    constructor(){}
    sendUserWebsocketMessage(webSocketMessage: IWebSocketMessage){

   //     this.userWebsocket.sendMessage(webSocketMessage);
    }



}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor(private http: HttpClient, private domSanitizer: DomSanitizer) {}

  async getPDF(pdfUrl): Promise<any>{
  //  const sanitize:any  = this.domSanitizer.bypassSecurityTrustHtml(pdfUrl)
  const sanitize:any   = this.domSanitizer.bypassSecurityTrustUrl(pdfUrl);
    console.log('sanitize', sanitize)


    return await lastValueFrom(this.http.get(sanitize, { responseType: 'blob' }))
     
  }
}
import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectionStrategy, inject, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router'
import { LogoutDialogComponent } from '@sybl/feature-auth-ui'

import { AppStatusStore } from '@sybl/core-state';
import { ISideNavUrlLink } from '@sybl/common-models';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip'
import { SvgIconComponent } from '@sybl/feature-custom-elements-ui'

@Component({
  standalone: true,
  imports: [CommonModule, MatTooltipModule, SvgIconComponent],
  selector: 'lib-left-sidenav-item',
  templateUrl: './left-sidenav-item.component.html',
  styleUrls: ['./left-sidenav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LeftSidenavItemComponent implements OnInit {

  @Input() profileLink!: ISideNavUrlLink;
  @Input() user_id:string;
  @Input() permissions
  @Output() leftMenuToggle = new EventEmitter<any>()

  readonly appStatusStore = inject(AppStatusStore);


  iconColor!:'white';

  constructor(
    public dialog: MatDialog,
    private router: Router,
  ){ }

  ngOnInit(): void {
    return
  }


  clickLink(){
    this.appStatusStore.closeRightSidenav()


    //If link is logout do popup dialog
    if(this.profileLink.url==='logout'){
      return this.dialog.open(LogoutDialogComponent)
    }
    else if(this.profileLink.url==='zenDesk'){
      window.open("https://linqurainc.zendesk.com/")
    }
    else {
      /**
       * All Navigation links initiate pageLoading action and navigate to corresponding page.
       */
      this.appStatusStore.pageLoading();

      const linkUrl = this.profileLink.url;
      if (linkUrl.includes('stripe')){
        if (this.appStatusStore.environment() != 'PRODUCTION'){
          window.open(linkUrl, '_blank')
        } else {
          window.open('https://billing.stripe.com/p/login/9AQ5lw2Il3Lz8245kk', '_blank')
        }}

      if (linkUrl.includes('stripe')){
        window.open(linkUrl, '_blank')
      }
      const linkParts = linkUrl.split('/');
      if(linkParts[1] === 'user'){
        const formatLink = linkUrl + '/' + this.user_id;

        this.router.navigate([formatLink])
      }

      this.router.navigate([linkUrl])
      // Append user_id to user links.

      // Parse the routes
    }

    return this.leftMenuToggle.emit();

  }

  get url(){
    return this.profileLink.url;
  }

  get displayName(){

    return this.profileLink.displayName;
  }

  get tooltipText(){
    return this.profileLink.description;
  }

  get visible(){
    return this.profileLink.visible;
  }

  get iconName(){
    return this.profileLink.iconName;
  }
}

import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-bottom-info-box',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bottom-info-box.component.html',
  styleUrl: './bottom-info-box.component.scss',
})
export class FeatureBottomInfoBoxComponent {
  facebookIcon: string
  instagramIcon: string
  twitterIcon: string
  linkedInIcon: string
  youTubeIcon: string

  instagramUrl = 'https://www.instagram.com/';
  patreonUrl = 'https://www.patreon.com/';
  twitterUrl = 'https://twitter.com/';
  facebookUrl = 'https://www.facebook.com/';
  linkedInUrl = ' https://www.linkedin.com/company/';
  youTubeUrl = ' https://www.youtube.com/';

  instagramId = '';
  patreonId = '';
  twitterId = '';
  facebookId = '';
  linkedInId = 'linqura';
  youTubeId = '@Linqura'
  constructor(
    @Inject('appUrls') private appUrls: any, // EnvironmentAppUrls,
    private router: Router,
  ) {
    this.facebookIcon = this.appUrls.IMAGES_URL + 'facebook_30.png';
    this.instagramIcon = this.appUrls.IMAGES_URL + 'ig-logo-gradient.png';
    this.twitterIcon = this.appUrls.IMAGES_URL + 'X_logo_white.svg';
    this.linkedInIcon = this.appUrls.IMAGES_URL + 'linked-in-logo.png';
    this.youTubeIcon = this.appUrls.IMAGES_URL + 'yt_icon_mono_dark.png';
  }




  clickedInstagram() {
    window.location.href = this.instagramUrl + this.instagramId;
  }

  clickedPatreon() {
    window.location.href = this.patreonUrl + this.patreonId;
  }

  clickedTwitter() {
    window.location.href = this.twitterUrl + this.twitterId;
  }

  clickedFacebook() {
    window.location.href = this.facebookUrl + this.facebookId;
  }

  clickedLinkedIn() {
    window.location.href = this.linkedInUrl + this.linkedInId;
  }

  clickedYouTube() {
    window.location.href = this.youTubeUrl + this.youTubeId;
  }

  clickedAboutUs() {
    return this.router.navigate(['/about/home']);
  }
  clickedAdvertise() {
    return this.router.navigate(['/contact/advertise']);
  }
  clickedContactUs() {
    return this.router.navigate(['/contact/contact-us']);
  }
  clickedCorrections() {
    return this.router.navigate(['/contact/corrections']);
  }
  clickedConditions() {
    return this.router.navigate(['/about/conditions']);
  }
  clickedPrivacy() {
    return this.router.navigate(['/about/privacy']);
  }
  clickedGroups() {
    return this.router.navigate(['/comedy-groups']);
  }
  clickedCopyright() {
    return this.router.navigate(['/contact/copyright-notice']);
  }
  clickedJobs() {
    return this.router.navigate(['/contact/jobs']);
  }
  clickedLibrary() {
    return this.router.navigate(['/library']);
  }
}

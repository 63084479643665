<div class="right-profile-item" (click)="clickLink()">
    <div class="list-text"
        [matTooltip]="tooltipText"
        matTooltipShowDelay="300"
        matTooltipClass="rightsidenav-notification-class"
        >
            <lib-svg-icon [color]="'white'" [iconName]="iconName" [size]="'large'"></lib-svg-icon>
        <div class="sidenav-item-text">
            {{ displayName }}

        </div>
    </div>
</div>

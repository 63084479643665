<div style="display: flex; flex-direction: row;">
@if(permissions && permissions >= 3 && username!==undefined && !mobileBrowser){
  <div style="margin: auto .5em;">
  {{username}}
</div>
}

<div style="width: 70px; font-family: 'aileron'; display: flex">
 @if(avatarUndefined && permissions >= 3){
    <div [ngClass]="_avatarSize">
      <div style="background-color: whitesmoke;
      border-radius: 36px;
      height: 70px;
      display: flex;
      ">

        <div class="undefined-avatar-toolbar" style="color: #222B4D;">{{ usernameInitial }}</div>
      </div>
    </div>
  } 
  @else if(avatarUndefined===false && permissions >= 3){
  <div>
    <img [ngClass]="_avatarSize" [src]="avatar" />
  </div>
  } @else{
      <img [ngClass]="_avatarSize" [src]="defaultAvatar" />
  
  }
</div>
</div>